import { render, staticRenderFns } from "./PerkDetails.vue?vue&type=template&id=6931317e&scoped=true"
import script from "./PerkDetails.vue?vue&type=script&lang=js"
export * from "./PerkDetails.vue?vue&type=script&lang=js"
import style0 from "./PerkDetails.vue?vue&type=style&index=0&id=6931317e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6931317e",
  null
  
)

export default component.exports