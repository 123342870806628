<template>
  <div class="perk-form">
    <v-form ref="form" class="perk-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="6">
          <v-row v-if="isUpdate">
            <v-col>
              <PerkRating :perk="perk" class="justify-end" />
            </v-col>
          </v-row>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            class="mt-2"
            :label="'Select buildings'"
            :placeholder="'Select buildings'"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :deletableChips="true"
            :isHasSelectAll="true"
            :items="perk ? perk.buildings : []"
          />
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Header</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Header"
            v-model="form.header"
            :error-messages="form.$getError('header')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Subheader</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Subheader"
            v-model="form.subheader"
            :error-messages="form.$getError('subheader')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Voucher Code</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Voucher Code"
            v-model="form.voucher_code"
            :error-messages="form.$getError('voucher_code')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :hide-on-start="isUpdate"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Content</label>
          <v-textarea
            flat
            solo
            required
            class="mt-2"
            placeholder="Content"
            v-model="form.content"
            :error-messages="form.$getError('content')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Terms & Conditions</label>
          <v-textarea
            flat
            solo
            required
            class="mt-2"
            placeholder="Terms & Conditions"
            v-model="form.terms"
            :error-messages="form.$getError('terms')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>

          <v-checkbox
            v-model="form.is_active"
            hide-details="auto"
            label="Set as active"
            class="mt-0"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Icon</label>
          <PerkIconUpload
            ref="iconUploader"
            :loading="form.$busy"
            :error="form.$getError('icon')"
            :for-update="isUpdate"
            @updated="form.$clearError('icon')"
            class="my-2"
            :showUploadImageBtn="hasUpdatePermission"
          />
          <p class="hint">Ideal image size 460px x 460px</p>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Thumbnail Banner</label>
          <PerkThumbnailBannerUpload
            ref="thumbnailUploader"
            :loading="form.$busy"
            :error="form.$getError('thumbnail_banner')"
            :for-update="isUpdate"
            @updated="form.$clearError('thumbnail_banner')"
            class="my-2"
            :showUploadImageBtn="hasUpdatePermission"
          />
          <p class="hint">Ideal image size 460px x 280px</p>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Banner</label>
          <PerkBannerUpload
            ref="bannerUploader"
            :loading="form.$busy"
            :error="form.$getError('banner')"
            :for-update="isUpdate"
            @updated="form.$clearError('banner')"
            class="my-2"
            :showUploadImageBtn="hasUpdatePermission"
          />
          <p class="hint">Ideal image size 685px x 600px</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Perk Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings.vue'
import PerkRating from '@/views/Home/Perks/components/PerkRating'
import PerkIconUpload from '@/views/Home/Perks/components/PerkIconUpload'
import PerkThumbnailBannerUpload from '@/views/Home/Perks/components/PerkThumbnailBannerUpload'
import PerkBannerUpload from '@/views/Home/Perks/components/PerkBannerUpload'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    PerkRating,
    PerkIconUpload,
    PerkThumbnailBannerUpload,
    PerkBannerUpload,
    SelectMultipleBuildings,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    perk: {
      type: Object,
      default: () => {
        return null
      },
    },
    accessBuilding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: new Form({
        header: '',
        subheader: '',
        voucher_code: '',
        building_id: [],
        content: '',
        terms: '',
        is_active: false,

        thumbs_up: 0,
        thumbs_down: 0,

        icon: null,
        thumbnail_banner: null,
        banner: null,
      }),
    }
  },

  computed: {
    ...mapState({
      createdPerk: (state) => state.perk.perkDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Perk Information' : 'Create Perk'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updatePerk : this.createPerk
    },

    isUpdate() {
      return !!this.perk || !!this.createdPerk
    },

    hasUpdatePermission() {
      if (this.isUpdate) {
        return (
          validatePermissions([PERMISSION.PERKS_UPDATE], this.permissions) &&
          this.accessBuilding
        )
      } else {
        return validatePermissions([PERMISSION.PERKS_CREATE], this.permissions)
      }
    },
  },

  watch: {
    perk() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createPerk: 'perk/createPerk',
      updatePerk: 'perk/updatePerk',
    }),

    initForm() {
      if (this.perk) {
        this.form.header = this.perk.header
        this.form.subheader = this.perk.subheader
        this.form.voucher_code = this.perk.voucher_code
        this.form.content = this.perk.content
        this.form.terms = this.perk.terms
        this.form.is_active = this.perk.is_active

        this.form.thumbs_up = this.perk.thumbs_up
        this.form.thumbs_down = this.perk.thumbs_down

        this.form.icon = this.perk.icon
        this.form.thumbnail_banner = this.perk.thumbnail_banner
        this.form.banner = this.perk.banner

        const buildingIds = [...this.perk.buildings].map((item) => item.id)
        this.form.building_id = buildingIds
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Perk details successfully updated!')
          } else {
            this.showSnackbar('Perk successfully created!')
            this.$router.push({
              name: 'perks',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      if (!this.$refs.iconUploader.hasData()) {
        this.form.$setError('icon', 'Icon is required')
      }

      if (!this.$refs.thumbnailUploader.hasData()) {
        this.form.$setError('thumbnail_banner', 'Thumbnail banner is required')
      }

      if (!this.$refs.bannerUploader.hasData()) {
        this.form.$setError('banner', 'Banner is required')
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      form.type = form.type ? form.type.toLowerCase() : null
      delete form.icon
      delete form.thumbnail_banner
      delete form.banner

      if (this.isUpdate) {
        form.id = this.perk.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          switch (field) {
            /* ...append normally everything else */
            case 'building_id':
              if (Array.isArray(form[field])) {
                form[field].forEach((id) =>
                  formData.append('building_id[]', id)
                )
              }
              break
            default:
              formData.append(field, form[field])
              break
          }
        }

        formData.append('icon', this.$refs.iconUploader.getImage())
        formData.append(
          'thumbnail_banner',
          this.$refs.thumbnailUploader.getImage()
        )
        formData.append('banner', this.$refs.bannerUploader.getImage())

        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.perk-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
